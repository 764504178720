
export default [
    {
        path: '/helpdesk',
        component: "__base",
        children: [
          {
            path: "queries",
            component: "QueriesAllList.vue",
            meta: { title: "Queries List" },   
            right: "helpdesk",
          },
          { 
            path: "queries/queriesview/:QueryHelpDeskId",
            component: "common/QueriesView.vue",
            meta: { title: "Queries Conversations" },
            right: "helpdesk",
        },
          {
            path: "*",
            component: "",
          },
        ],
    },
  ]
  
  
  